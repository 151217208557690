import React, { useEffect, useMemo, useState } from 'react';
import PaymentVerificationForm from './PaymentVerificationForm'
import { useDispatch, useSelector } from 'react-redux';
import { gql, useApolloClient } from '@apollo/client';
import { executeGraphQLMutation, executeGraphQLQuery } from '../../common/executeGraphQLQuery';
import { CHECK_ASSIGN_USER, CONFIRMATION_TXT, DisputeType, getKeysData, IS_USER_ACCESS_PAGE, PAYMENT_TYPE, PAYMENT_VERIFICATION_SCREENS, TRANCH_ORDER, PAYMENT_TYPES, TT_PAYMENT_LABEL, TT_PAYMENT_SORT_ORDER, TRANCHE_STATUS, PAYMENT_TYPES_ALTERNATE, PAYMENT_TYPE_LABEL, SUB_STATUS_CODES } from '../../config/constants';
import { Nav, Tab } from 'react-bootstrap'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import DocumentGallery from './DocumentGallery';
import crypto from '../../config/crypto';
import TitleTransferPayments from './TitleTransferPayments';
import Loader from '../elements/Loader';
import { getLoanDetail } from '../../store/action/allAction';
import { toast } from "react-toastify";
import { GET_BANK_LIST } from '../../services/masterdata.gql';
import PaymentRecovery from './payment-verification/PaymentRecovery';
import AdditionalPayments from './AdditionalPayment';
import { CREATE_ADDITIONAL_PAYMENT, DELETE_ADDITIONAL_PAYMENT, GET_ADDITIONAL_PAYMENT, REUPLOAD_DOC, UPDATE_ADDITIONAL_PAYMENT } from '../../services/leads.gql';
import generalfnction from '../../services/generalService';
import { GET_DEALER_DISPUTE_DATA, SAVE_PAYMENT_RECOVERY, SAVE_PAYMENT_VERIFICATION_ONE, SAVE_PAYMENT_VERIFICATION_TWO } from '../../services/dispute.gql';
import dateFormat from "dateformat";
import PaymentVerificationTwo from './payment-verification/PaymentVerificationTwo';
import Modal from '../elements/Modal';
import { generateKeyValuePair } from '../../common/helper';

const PaymentVerification = ({current_page,  setIsLoading}) => {
    const common_fields = { is_edit: false, is_save: 0, status: "1", is_tranche_marked: false } || {};
    const user_information = useSelector((state) => state.user.user_information);
    const userInfo = JSON.parse(user_information);
    const { id: user_id } = userInfo;
    const [selectedTab, setSelectedTab] = useState("first");
    const [numPayments, setNumPayments] = useState(0);
    const [ttPaymentDetails, setTtPaymentDetails] = useState([]);
    const [savedAdditionalPayments, setSavedAdditionalPayments] = useState([])
    const [additionalPaymentDetails, setAdditionalPaymentDetails] = useState([]);
    const [additionalPaymentOptions, setAdditionalPaymentOptions] = useState([])
    const [additionalPaymentRemOptions, setAdditionalPaymentRemOptions] = useState([])
    const [bankList, setBankList] = useState([]);
    const [disabledbutton,setDisabledbutton]=useState(false);
    const [loading, setLoading] = useState(false);
    const [isShowPaymentRecovery, setIsShowPaymentRecovery] = useState(true);
    const [screenToShow, setScreenToShow] = useState(current_page);
    const [payViaDisputed, setPayViaDisputed] = useState(false);
    const [disputeData, setDisputeData] = useState([]);
    const [paymentVerificationDetails, setPaymentVerificationDetails] = useState([]);
    const [splitPaymentDetails, setSplitPaymentDetails] = useState([]);
    const [tranchData, setTranchData] = useState([]);
    const [recoveryData, setRecoveryData] = useState({});
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [confirmationChecked, setConfirmationChecked] = useState(false);
    const [splitTrancheKeyPair, setSplitTrancheKeyPair] = useState({});

    const navigate = useNavigate();

  useEffect(() => {
    setScreenToShow(current_page);
  }, [current_page]);
  
    const { lead, leadDetail } = useSelector(({masterdata, lead}) => ({
      masterdata,
      lead,
      leadDetail: lead?.leadDetail,
      remarksHistory:lead.remarksHistory || []
    }));
  
  
  let isPaidViaRecoverySelected = useMemo(() => {
    return leadDetail?.lead_status_history?.find(({ sub_status_id }) => +sub_status_id === SUB_STATUS_CODES.PAYMENT_VERIFICATION_ONE) ? true : false;
  }, [leadDetail?.lead_status_history]);

    const [showDocs, setShowDocs] = useState([])
    const [showEditDocs, setShowEditDocs] = useState([])

    const [ addiditionalPaymentAmount, setAdditionalPaymentAmount] = useState({
      main_dealer: 0,
      onhold_dealer:0,
      split_dealer: 0,
      split_dealer2: 0
    })
    const financierId = useMemo(() => {
      return leadDetail?.financier?.filter(data => data.is_proceed === 'yes')?.[0]?.financier_id
    }, [leadDetail?.financier]);
    let params = useParams();
    let lead_id = crypto.decode(params.lead_id)
    const client = useApolloClient();
    const dispatch = useDispatch();
    const getContext = useOutletContext();
    const documentCategoryList = getContext.docList || []
    let accessCondition = !IS_USER_ACCESS_PAGE("payment_verification", "payment_verification")?.is_edit_access;
    if(!accessCondition){
        accessCondition=CHECK_ASSIGN_USER(lead?.leadDetail?.assign_to);
    }
    const isADPCase = useMemo(() => {
      return leadDetail?.lead_computation_details?.adp_type === "Yes"; 
    }, [leadDetail?.lead_computation_details?.adp_type]);

    useEffect(() => {
      if(leadDetail?.title_payment && bankList?.length){
        let ttData = leadDetail?.title_payment?.filter(payment => payment?.status !== "2")?.map((data, index) => {
          return {
          ...data,
          "bank_name": bankList?.find(bank => bank?.label === data?.bank_name.replace(/&amp/g, '&'))?.value || 0,
          "label": TT_PAYMENT_LABEL[data?.payment_type] || "",
          "index": index,
          "is_edit":false,
          "is_save": 1,
          "status":"1",
          "is_tranche_marked": true
        }}).sort((a, b)=> TT_PAYMENT_SORT_ORDER[a?.payment_type] - TT_PAYMENT_SORT_ORDER[b?.payment_type]);
        setTtPaymentDetails(ttData)
        setNumPayments(ttData?.length)
      }
    }, [bankList, leadDetail?.title_payment]);

    useEffect(() => {
      const fetchData = async () => {
          await getBankList();
      };
      fetchData();
    }, []);

    useEffect(()=>{
      if (leadDetail?.split_payment?.length) {
        setSplitTrancheKeyPair(prev => ({
          ...generateKeyValuePair(leadDetail?.split_payment, "payment_type")
        }))
      }
    }, [leadDetail?.split_payment])

  useEffect(() => {
      if([PAYMENT_VERIFICATION_SCREENS.PAYMENT_VERIFICATION_ONE, PAYMENT_VERIFICATION_SCREENS.PAYMENT_VERIFICATION_TWO].includes(current_page)){
        fetchAdditionalPayemnts();
        additionalPaymentOptionsHelper()
      }
    },[leadDetail, bankList])

    const additionalPaymentOptionsHelper = async() =>{
      let payments = []
      let updates = {}
      leadDetail?.split_payment?.forEach((payment)=>{
        if(payment?.tranche_status === 'paid'){
          if(payment.payment_type === PAYMENT_TYPES.MAIN_DEALER){
            payments.push({heading: "Extra Main Dealer Payment", label:"Main Dealer", value:PAYMENT_TYPES.EXTRA_MAIN_DEALER})
            updates = {...updates, main_dealer: payment.amount}
          }
          else if(payment.payment_type === PAYMENT_TYPES.ON_HOLD_DEALER){
            payments.push({heading: "Extra On Hold Dealer Payment", label:"On Hold Dealer", value:PAYMENT_TYPES.EXTRA_ON_HOLD_DEALER})
            updates = {...updates, onhold_dealer: payment.amount}
          }
          else if(payment.payment_type === PAYMENT_TYPES.SPLIT_DEALER){
            payments.push({heading: "Extra Split Dealer Payment", label:"Split Dealer 1", value:PAYMENT_TYPES.EXTRA_SPLIT_DEALER})
            updates = {...updates, split_dealer: payment.amount}
          }  
          else if(payment.payment_type === PAYMENT_TYPES.SPLIT_DEALER_2){
            payments.push({heading: "Extra Split Dealer 2 Payment", label:"Split Dealer 2", value:PAYMENT_TYPES.EXTRA_SPLIT_DEALER_2})
            updates = {...updates, split_dealer2: payment.amount}
          }
        }
      })
      // setAdditionalPaymentOptions(payments)
      // setAdditionalPaymentAmount(updates)
    }

    const fetchAdditionalPayemnts= async() =>{
      let variables ={
        lead_id: Number(lead_id),
        payment_mode: current_page
      }
      setIsLoading(true);
      executeGraphQLMutation(GET_ADDITIONAL_PAYMENT, variables, client)
        .then((res) => {
          let payments = res?.data?.get_additional_payments.map((payment) => {
            let paymentData = {
              ...payment,
              account: payment?.account_number,
              is_checked: false,
              new_docs: []
            };
            return paymentData;
          });
          setSavedAdditionalPayments([...payments]);
          setAdditionalPaymentDetails([...payments]);
          setShowDocs([]);
          setShowEditDocs([]);
        })
        .finally(_ => setIsLoading(false))
    }

    const getBankList = async() => {
      setLoading(true);
          await executeGraphQLQuery(GET_BANK_LIST(),client).then((response) => {
              if (response?.data?.bank_list) {
                setBankList(response?.data?.bank_list);
              }
          }).catch((error) => {
              console.log(error);
          });
      setLoading(false);
    }


    const handleAddPayments = () => {
      let payment_num = ttPaymentDetails?.length ? ttPaymentDetails?.filter(data => data?.status === '1')?.length+1 : numPayments+1;
      setNumPayments(payment_num);
      let tt_payments = ttPaymentDetails;
      tt_payments.push({
        "id": 0,
        "label": "Title Transfer Payment "+payment_num,
        "payment_type":"title_transfer_payment"+payment_num,
        "bank_name" : null,
        "branch_name" : null,
        "account_name" : null,
        "account_number" : null,
        "amount" : 0,
        "is_edit":true,
        "is_save": 0,
        "status":"1",
        "is_tranche_marked": false,
        "index": ttPaymentDetails?.length
      })
      setTtPaymentDetails(tt_payments);
    };

  const handleAddAdditionalPayments = () => {
    const addPaymentTypes = additionalPaymentDetails?.map(item => item?.payment_type) || [];
    const payment_type = additionalPaymentRemOptions?.find(item => !addPaymentTypes?.includes(item?.value))?.value || "";
    if (!payment_type) return;
    const newPayment = {
      payment_type,
      bank_name: null,
      branch_name: null,
      account_name: null,
      acc_num: null,
      amount: 0,
      docs: [],
      is_edit: true,
      is_save: 0,
      status: "1",
      is_checked: true,
      is_edit_allowed: true,
      index: additionalPaymentDetails?.length,
    };
    
    const updatedPayments = [...additionalPaymentDetails, newPayment];
    setAdditionalPaymentDetails([...updatedPayments]);
  };

    const handleEditChange = (index) => {
        let updatedttPayment = [...ttPaymentDetails];
        index = updatedttPayment.findIndex(obj => +obj?.index === +index)
        updatedttPayment[index] = {...updatedttPayment[index], "is_edit": !updatedttPayment[index]["is_edit"]}
        setTtPaymentDetails(updatedttPayment);
    }

    const handleEditAdditionalChange = (index) => {
      let updatedttPayment = [...additionalPaymentDetails];
      index = updatedttPayment.findIndex(obj => +obj?.index === +index)
      updatedttPayment[index] = {...updatedttPayment[index], "is_edit": !updatedttPayment[index]["is_edit"]}
      setAdditionalPaymentDetails(updatedttPayment);
    }

    const handleDeleteChange = async(index) => {
        let updatedttPayment = [...ttPaymentDetails], count = 0;
        index = updatedttPayment.findIndex(obj => +obj?.index === +index);
        if(updatedttPayment[index]['is_save'] === 1)
          updatedttPayment[index] = {...updatedttPayment[index], "is_save":0, "payment_type": null, "status": "2"}
        else
          updatedttPayment = updatedttPayment.filter((obj, i) => i !== index);
        updatedttPayment = updatedttPayment?.map((payment, i) => {
          if(["generated"].includes(payment.tranche_status) || !payment?.tranche_status){
            if(payment?.status === "1") count++;
            let obj = payment?.status === "1" ? {
              ...payment,
              index : i,
              label: "Title Transfer Payment "+count,
              payment_type : "title_transfer_payment"+count
            } : { ...payment, index : i }
            return obj;
          }else{
            return { ...payment, index : i }
          }
        })
        setTtPaymentDetails(updatedttPayment);
        if(numPayments-1 >= 0) setNumPayments(numPayments-1);
        else setNumPayments(0);
        if(updatedttPayment[index]?.id) await deleteTranche(updatedttPayment[index]);
    }

    const handleAdditionalDeleteChange = async(index) => {
      let updatedttPayment = [...additionalPaymentDetails], count = 0;
      index = updatedttPayment.findIndex(obj => +obj?.index === +index);
      if(updatedttPayment[index]['is_save'] === 1)
        updatedttPayment[index] = {...updatedttPayment[index], "is_save":0, "payment_type": null, "status": "2"}
      else
        updatedttPayment = updatedttPayment.filter((obj, i) => i !== index);
      updatedttPayment = updatedttPayment?.map((payment, i) => {
        if(["generated"].includes(payment.tranche_status) || !payment?.tranche_status){
          if(payment?.status === "1") count++;
          let obj = payment?.status === "1" ? {
            ...payment,
            index : i,
            label: "Title Transfer Payment "+count,
            payment_type : "title_transfer_payment"+count
          } : { ...payment, index : i }
          return obj;
        }else{
          return { ...payment, index : i }
        }
      })
      setTtPaymentDetails(updatedttPayment);
      if(numPayments-1 >= 0) setNumPayments(numPayments-1);
      else setNumPayments(0);
      if(updatedttPayment[index]?.id) await deleteTranche(updatedttPayment[index]);
    }

    const handleInputChange = (target_id, event, type, index) => {
      let value = type === "number-format" ? event.floatValue : type === 'dropdown' ? event.value : event.target.value;
      if(value && value !== undefined){
        let updatedTTPayment = [...ttPaymentDetails];
        index = updatedTTPayment?.findIndex(obj => +obj?.index === +index);
        updatedTTPayment[index] = {
          ...updatedTTPayment[index],
          [target_id]: value,
          "is_save": 0
        }
        setTtPaymentDetails(updatedTTPayment);
      } 
    }

    const deleteTranche = async(trancheToDelete) => {
      setLoading(true);
      let titleTransferInput = [{
        "id": trancheToDelete?.id || 0,
        "lead_id": Number(lead_id),
        "payment_type": trancheToDelete?.payment_type || "",
        "bank_name" : bankList?.find(bank => bank.value === trancheToDelete?.bank_name)?.label || "",
        "branch_name" : trancheToDelete?.branch_name || "",
        "account_name" : trancheToDelete?.account_name || "",
        "account_number" : trancheToDelete?.account_number ? String(trancheToDelete?.account_number) : "",
        "amount" : trancheToDelete?.amount || 0,
        "is_tranche_marked": trancheToDelete?.is_tranche_marked,
        "status" : trancheToDelete?.status,
        "requested_by_name": userInfo?.name || "",
        "created_by": userInfo?.id || 0,
        "updated_by": userInfo?.id || 0
      }];
      //if user has selected some tranches then only proceed ahead
      if(titleTransferInput?.length){
        let finalResponse = {
          "lead_id": Number(lead_id),
          "title_payment": titleTransferInput || [],
          "created_by": userInfo?.id || 0
        }
        let variables = {
          titleTransferInput : finalResponse
        } 
        let mutation = gql`mutation saveTitleTransferDetails($titleTransferInput: TitleTransferPaymentInput!) {
          title_transfer_save(titleTransferInput: $titleTransferInput) {
            success
          }
        }`;

        try {
          await executeGraphQLMutation(mutation, variables, client);
            dispatch(getLoanDetail(lead_id, client));
            toast.success("Tranche Deleted Successfully!");
        } catch (error) {
            toast.error("Failed");
            console.error(error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
        toast.error("Please select a Payment Tranche!")
      }
    }

    const handleAdditionalInputChange = (target_id, event, type, index, mode='create') => {
      let value = type === "number-format" ? event.floatValue : type === 'dropdown' ? event.value : event.target.value;
      if(target_id === 'payment_type'){
        let flag = false;
        additionalPaymentDetails.forEach((payment)=> {
          if(payment.payment_type === value && value!==undefined)
            flag=true
        })
        additionalPaymentDetails.forEach((payment)=> {
          if(payment.payment_type === value && value!==undefined)
            flag=true
        })

        if(flag===true) return;
      }

      if(value && value !== undefined){
        let updatedTTPayment = mode==='edit'?[...additionalPaymentDetails]:[...additionalPaymentDetails];
        index = mode==='edit' ? updatedTTPayment?.findIndex(obj => +obj?.id === +index): updatedTTPayment?.findIndex(obj => +obj?.index === +index);

        if(target_id === 'amount'){
          if(addiditionalPaymentAmount[updatedTTPayment?.[index]?.payment_type]< value)
            return;
        }
        updatedTTPayment[index] = {
          ...updatedTTPayment[index],
          [target_id]: value,
          "is_save": 0
        }

        
        mode==='edit'? setAdditionalPaymentDetails(updatedTTPayment) :setAdditionalPaymentDetails(updatedTTPayment);
      } 
    }

    const handleSubmit = async() => {
      setLoading(true);
      let titleTransferInput = ttPaymentDetails?.filter(data => (!data?.tranche_status || (data?.tranche_status && !['requested', 'paid']?.includes(data?.tranche_status))))?.map(payment => {return {
        "id": payment?.id || 0,
        "lead_id": Number(lead_id),
        "payment_type":payment?.payment_type || "",
        "bank_name" : bankList?.find(bank => bank.value === payment?.bank_name)?.label || "",
        "branch_name" : payment?.branch_name || "",
        "account_name" : payment?.account_name || "",
        "account_number" : payment?.account_number ? String(payment?.account_number) : "",
        "amount" : payment?.amount || 0,
        "is_tranche_marked": payment?.is_tranche_marked,
        "status" : payment?.status,
        "requested_by_name": userInfo?.name || "",
        "created_by": userInfo?.id || 0,
        "updated_by": userInfo?.id || 0
      }})
      //if user has selected some tranches then only proceed ahead
      if(titleTransferInput?.length){
        let finalResponse = {
          "lead_id": Number(lead_id),
          "title_payment": titleTransferInput || [],
          "created_by": userInfo?.id || 0
        }
        let condition = !leadDetail?.title_payment?.length || (leadDetail?.title_payment?.length && !ttPaymentDetails?.filter(data => data.is_save === 1)?.length && !leadDetail?.title_payment?.filter(data => data.tranche_status === "on_hold")?.length) || (leadDetail?.title_payment?.length && !leadDetail?.title_payment?.filter(data => data.tranche_status)?.length);
        let variables = condition ? {
          titleTransferInput : finalResponse
        } : {
          sendTitleTrancheInput : finalResponse
        }
        let mutation = condition ? gql`mutation saveTitleTransferDetails($titleTransferInput: TitleTransferPaymentInput!) {
          title_transfer_save(titleTransferInput: $titleTransferInput) {
            success
          }
        }`: gql`
        mutation send_title_tranche($sendTitleTrancheInput:TitleTransferPaymentInput!) {
          send_title_tranche(sendTitleTrancheInput: $sendTitleTrancheInput) {
            success
          }
        }`;

        try {
          await executeGraphQLMutation(mutation, variables, client);
            dispatch(getLoanDetail(lead_id, client));
            toast.success("Success");
        } catch (error) {
            toast.error("Failed");
            console.error(error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
        toast.error("Please select a Payment Tranche!")
      }
    }
  
  
    let isPaymentVerificationDoneMarked = useMemo(() => {
      return leadDetail?.lead_status_history?.find(({ sub_status_id }) => +sub_status_id === SUB_STATUS_CODES.PAYMENT_VERIFICATION_DONE) ? true : false;
    }, [leadDetail?.lead_status_history]);
    useEffect(() => {
      if (!isPaymentVerificationDoneMarked && leadDetail?.dealer_id) getDisputeData(leadDetail?.dealer_id);
    }, [leadDetail?.dealer_id]);
    const getDisputeData = async (dealer_id) => {
      const variables = { dealer_id };
      await executeGraphQLMutation(GET_DEALER_DISPUTE_DATA, variables, client)
        .then(resp => {
          if (resp?.data?.get_dealer_dispute_data?.length) setDisputeData([...resp?.data?.get_dealer_dispute_data]);
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    };
  
    useEffect(() => {
      const fetchData = async () => {
          await getPaymentVerificationDetails();
      };
      fetchData();
  }, [leadDetail]);

  const getPaymentVerificationDetails = () => {
    let mandatory_fields = {
      "tin_number": leadDetail?.dealerData?.[0]?.["tin_id"],
      "accreditation_date": dateFormat(leadDetail?.dealerData?.[0]?.["accreditation_date"], 'yyyy-mm-dd'),
      "loan_value": leadDetail?.lead_computation_details?.loan_amount_pass_through === null ? leadDetail?.lead_computation_details?.loan_value : leadDetail?.lead_computation_details?.final_loan_value,
      "total_dealer_incentive_of_tax": leadDetail?.lead_computation_details?.loan_amount_pass_through === null ? leadDetail?.lead_computation_details?.total_dealer_incentive_net_of_tax : leadDetail?.lead_computation_details?.dealer_incentive_net_of_tax,
      "total_payment_to_dealer": leadDetail?.lead_computation_details?.loan_amount_pass_through === null ? leadDetail?.lead_computation_details?.total_dealer_payment : leadDetail?.lead_computation_details?.payment_to_dealer
    };

    let split_payment_details = leadDetail?.split_payment || [];
    let mainDealerFlag = leadDetail?.lead_computation_details?.adp_type === "Yes" || leadDetail?.lead_computation_details?.loan_amount_pass_through !== 'yes';
    const { isSplitTranchPush } = split_payment_details?.reduce((result, split) => {
      if ([PAYMENT_TYPE.SplitDealer, PAYMENT_TYPE.SplitDealer2].includes(split?.payment_type)) result.isSplitTranchPush = true;
      if ([PAYMENT_TYPE.MainDealer].includes(split?.payment_type)) result.mainDealerFlag = true;
      return result;
    }, { isSplitTranchPush: false });
    let sortedSplitPayment = [...split_payment_details] || [];
    let bank_data_index = mainDealerFlag ? 1 : 0;
    if (split_payment_details?.length) {
      sortedSplitPayment = sortedSplitPayment.map((obj, index) =>{
          if(isADPCase ? index > 1 : index !== 0){
            let bank_account_flag = leadDetail?.dealerData?.[0]?.bank_details?.some(data => data.account_number === obj.account_number);
              return {...obj, bank_account: bank_account_flag ? obj["account_number"] + " - " + obj["branch_name"] : "custom", is_edit : false, is_save: 1}
          }else{
              return {...obj, bank_account: obj["account_number"] + " - " + obj["branch_name"] , is_edit : true, is_save: 1};
          }
      })
    } else if (mainDealerFlag) {
      const dealer_bank_details = leadDetail?.dealerData?.[0]?.["bank_details"]?.[0] || {};
      sortedSplitPayment.push({
        ...common_fields,
        ...getKeysData(dealer_bank_details, ["bank_name", "branch_name", "account_holder_name", "account_number"]),
        "payment_type": PAYMENT_TYPE.MainDealer,
        "bank_account": dealer_bank_details?.["account_number"] + " - " + dealer_bank_details?.["branch_name"], 
        "amount": isADPCase ? (leadDetail?.lead_computation_details?.advance_loan_value_to_be_paid) : (leadDetail?.lead_computation_details?.loan_amount_pass_through === null ? leadDetail?.lead_computation_details?.total_dealer_payment : mainDealerFlag ? leadDetail?.lead_computation_details?.final_loan_value : leadDetail?.lead_computation_details?.dealer_incentive_net_of_tax),
      })
      if (isADPCase) {
        sortedSplitPayment.push({
          ...common_fields,
          ...getKeysData(dealer_bank_details, ["bank_name", "branch_name", "account_holder_name", "account_number"]),
          "payment_type": PAYMENT_TYPE.OnHoldDealer,
          "bank_account": dealer_bank_details?.["account_number"] + " - " + dealer_bank_details?.["branch_name"], 
          "amount": leadDetail?.lead_computation_details?.remaining_loan_value_to_be_paid,
        })
      }
    }
    if (leadDetail?.lead_computation_details?.dealer_incentive_net_of_tax > 0 && !isSplitTranchPush) {
      const dealer_bank_details = leadDetail?.dealerData?.[0]?.["bank_details"]?.[bank_data_index] || {};
      sortedSplitPayment.push({
        ...common_fields,
        ...getKeysData( dealer_bank_details, ["bank_name", "branch_name", "account_holder_name", "account_number"]),
        "payment_type": PAYMENT_TYPE.SplitDealer,
        "bank_account": dealer_bank_details?.["account_number"] + " - " + dealer_bank_details?.["branch_name"], 
        "amount": isADPCase ? (leadDetail?.lead_computation_details?.dealer_incentive_net_of_tax_to_be_paid) : leadDetail?.lead_computation_details?.loan_amount_pass_through === null ? leadDetail?.lead_computation_details?.total_dealer_payment : leadDetail?.lead_computation_details?.dealer_incentive_net_of_tax,
      })
    }
    //sort split payment details array
    sortedSplitPayment?.sort((a, b) => {
      return TRANCH_ORDER[a.payment_type] - TRANCH_ORDER[b.payment_type];
    });
    setPaymentVerificationDetails(mandatory_fields);
    setSplitPaymentDetails(sortedSplitPayment);
  }

  const handleDateChange = (date, field_name) => {
    if (date && field_name) {
        setPaymentVerificationDetails(currentValue => ({
            ...currentValue,
            [field_name]: dateFormat(date, 'yyyy-mm-dd')
        }))
    }
  }

  const handleAdditionalPaymentSubmit = async () => {
    let additionalPayment = [];
    additionalPaymentDetails.forEach((payment) => {
        let data = {
            lead_id: Number(lead_id),
            payment_type: payment?.payment_type,
            bank_name: payment?.bank_name,
            branch_name: payment?.branch_name,
            account_holder_name: payment?.account_holder_name,
            account_number: payment?.account,
            amount: payment?.amount.toString(),
            payment_mode: current_page,
            is_tranche_marked: payment?.is_checked,
            docs: payment?.new_docs || []
        };
        additionalPayment.push(data);
    });

    const variables = {
        userId: userInfo?.id || 0,
        additionalPaymentInput: additionalPayment
    };
    setIsLoading(true);
    executeGraphQLMutation(CREATE_ADDITIONAL_PAYMENT, variables, client)
      .then((res) => {
        toast.success("Payment added successfully");
        fetchAdditionalPayemnts();
      })
      .catch(err => {
        toast.error(err);
      })
      .finally(_ => setIsLoading(false));
};
  const updateAdditionalPayment = (payment) =>{
    let data = {
      lead_id: Number(payment.lead_id),
      payment_type: payment.payment_type,
      bank_name: payment.bank_name,
      branch_name: payment.branch_name,
      account_holder_name: payment.account_holder_name,
      account_number: payment.account_number,
      payment_mode: current_page,
      amount: payment.amount.toString(),
      docs: payment.new_docs || []
    }

    const variables = {
      user_id: userInfo?.id || 0,
      payment_id: payment?.id || 0,
      updateAdditionalPaymentInput: data
    }

    executeGraphQLMutation(UPDATE_ADDITIONAL_PAYMENT, variables ,client)
    .then((res)=>{
      fetchAdditionalPayemnts()
      toast.success("Payment Successfully updated")
    })
    .catch(e=>toast.error(e))
  }

  const deleteAdditionalPayment = (payment, index) => {
    setAdditionalPaymentDetails([...additionalPaymentDetails?.filter((item, tranchIndex) => index !== tranchIndex)])
    if (!payment?.id) {
      return;
    }
    const variables = {
      userId: userInfo?.id || 0,
      lead_id: payment?.lead_id,
      payment_id: payment?.id
    }

    setIsLoading(true)
    executeGraphQLMutation(DELETE_ADDITIONAL_PAYMENT, variables, client)
      .then((res) => {
        toast.success("Payment Successfully Deleted");
      })
      .catch(e => toast.error(e))
      .finally(_ => setIsLoading(false));
  }

  const handleReupload = (e, doc, payment) =>{
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('upload_type', 'finance_docs');
    formData.append("lead_id", "lead_id");
    formData.append('images', file);
    e.target.value = '';
    setIsLoading(true);
    generalfnction.uploadFilesToS3(formData, { 'Content-Type': 'multipart/form-data' }).then(async (res) => {
      if (res?.data?.data) {
        let file_url = res?.data?.data?.[0].file_url;
        let varaibles = {
          id: doc.id || 0,
          lead_id: Number(lead_id),
          payment_type: payment.payment_type,
          payment_mode: current_page,
          doc_path: file_url,
          user_id: userInfo?.id || 0,
        };
        executeGraphQLMutation(REUPLOAD_DOC, varaibles, client).
          then((res) => {
            if (res.data)
              toast.success("Doc Reuploaded Successfully!");
            dispatch(getLoanDetail(lead_id, client));
          })
          .catch(e => {
            toast.error("Error occured while reuploading");
          });
      }
    })
      .finally(() => setIsLoading(false))
  }
  

  const savePaymentVerificationOne = async () => {
    const isTranchSelected = splitPaymentDetails?.filter(item => item?.is_tranche_marked);
    if (!isTranchSelected?.length) {
      toast.error(`Select atleast one tranch!`);
      return;
    }
    if (!paymentVerificationDetails["loan_value"] && !paymentVerificationDetails["total_dealer_incentive_of_tax"]) {
      toast.error(`Tranch does't exists`);
      return;
    }
    let splitPayment = splitPaymentDetails?.reduce((result, obj) => {
      result.push({
        "lead_id": Number(lead_id),
        "payment_type": obj.payment_type,
        "bank_name": obj.bank_name || "",
        "branch_name": obj.branch_name || "",
        "account_holder_name": obj.account_holder_name || "",
        "account_number": obj.account_number || "",
        "amount": Number(obj.amount),
        "status": obj.status,
        "is_tranche_marked": obj.is_tranche_marked,
        is_custom_bank_selected: obj?.bank_account === "custom" ? true : false
      });
      return result;
    }, []);
    let variables = {
      save_payment_verification_one_input: {
        "lead_id": Number(lead_id),
        "mandatory_fields": paymentVerificationDetails,
        "split_payment": splitPayment,
        "created_by": user_id
      }
    };
    setLoading(true);
    await executeGraphQLMutation(SAVE_PAYMENT_VERIFICATION_ONE, variables, client)
      .then(resp => {
        if (resp?.data?.savePaymentVerificationOne) {
          dispatch(getLoanDetail(lead_id, client));
          toast.success(resp?.data?.savePaymentVerificationOne?.message || "Success");
          navigate(`/lead-detail/payment-recovery/${crypto.encode(lead_id)}`);
        }
      })
      .catch(error => {
        toast.error(error.toString().replace('ApolloError:', ''));
        console.error(error);
      })
      .finally(_ => {
        setLoading(false);
      });
  };

  const savePaymentRecovery = async () => {
    let splitPayment = tranchData?.reduce((result, split) => {
      if (split?.is_tranche_marked || split?.is_tranche_marked_for_recovery) {
        const tranch_recovery_payment = [];
        fetchTranchRecoveryPayment(split, split?.disputedCases, tranch_recovery_payment, DisputeType.Disputed);
        fetchTranchRecoveryPayment(split, split?.lostCases, tranch_recovery_payment, DisputeType.LostAfterDisbursal);
        result.push({
          "lead_id": Number(lead_id),
          "dispute_type": split.dispute_type, 
          "payment_type": split.payment_type,
          "bank_name": split.bank_name || "",
          "branch_name": split.branch_name || "",
          "account_holder_name": split.account_holder_name || "",
          "account_number": split.account_number || "",
          "amount": Number(split.amount),
          "amount_to_be_paid": Number(split.amount_to_be_paid),
          "status": split.status,
          "is_tranche_marked": split.is_tranche_marked,
          "tranch_recovery_payment": tranch_recovery_payment || []
        });        
      }
      return result;
    }, []);
    if (!splitPayment?.length) {
      toast.error(`Something went wrong, Try to refresh the page!`);
      return;
    }
    let variables = {
      save_payment_recovery_input: {
        "lead_id": Number(lead_id),
        "split_payment": splitPayment,
        "created_by": user_id
      }
    };
    setLoading(true);
    await executeGraphQLMutation(SAVE_PAYMENT_RECOVERY, variables, client)
      .then(resp => {
        if (resp?.data?.savePaymentRecovery) {
          dispatch(getLoanDetail(lead_id, client));
          toast.success(resp?.data?.savePaymentRecovery?.message || "Success");
          navigate(`/lead-detail/payment-verification-two/${crypto.encode(lead_id)}`)
        }
      })
      .catch(error => {
        toast.error(error.toString().replace('ApolloError:', ''));
        console.error(error);
      })
      .finally(_ => {
        setLoading(false);          
      });
  };

  const savePaymentVerificationTwo = async () => {
    const isTranchSelected = splitPaymentDetails?.filter(item => item?.is_tranche_marked);
    if (!isTranchSelected?.length) {
      toast.error(`Select atleast one tranch!`);
      return;
    }
    if (!paymentVerificationDetails["loan_value"] && !paymentVerificationDetails["total_dealer_incentive_of_tax"]) {
      toast.error(`Tranch does't exists`);
      return;
    }
    let splitPayment = splitPaymentDetails?.reduce((result, obj) => {
      result.push({
        "lead_id": Number(lead_id),
        "payment_type": obj.payment_type,
        "bank_name": obj.bank_name || "",
        "branch_name": obj.branch_name || "",
        "account_holder_name": obj.account_holder_name || "",
        "account_number": obj.account_number || "",
        "amount": Number(obj.amount),
        "status": obj.status,
        "is_tranche_marked": obj.is_tranche_marked,
        is_custom_bank_selected: obj?.bank_account === "custom" ? true : false,
        amount_paid_via_recovery: Number(obj?.amount_paid_via_recovery),
        amount_to_be_paid: Number(obj?.amount_to_be_paid),
        is_tranche_marked_for_recovery: obj?.is_tranche_marked_for_recovery,
      });
      return result;
    }, []);
    let variables = {
      save_payment_verification_two_input: {
        "lead_id": Number(lead_id),
        "mandatory_fields": paymentVerificationDetails,
        "split_payment": splitPayment,
        "created_by": user_id
      }
    };
    setLoading(true);
    hideConfirmation();
    await executeGraphQLMutation(SAVE_PAYMENT_VERIFICATION_TWO, variables, client)
      .then(resp => {
        if (resp?.data?.savePaymentVerificationTwo) {
          dispatch(getLoanDetail(lead_id, client));
          toast.success(resp?.data?.savePaymentVerificationTwo?.message || "Success");
        }
      })
      .catch(error => {
        toast.error(error.toString().replace('ApolloError:', ''));
        console.error(error);
      })
      .finally(_ => {
        setLoading(false);          
      });
  };

  const fetchTranchRecoveryPayment = (split, cases, tranch_recovery_payment, dispute_type) => {
    cases?.map(lead => {
      lead?.disputed_payment_type?.map(lead_split => {
        if (lead_split?.isChecked) {
          tranch_recovery_payment?.push({
            recovery_lead_id: split?.lead_id,
            recovery_payment_type: split?.payment_type,
            lead_id: lead?.lead_id,
            payment_type: PAYMENT_TYPE[lead_split?.payment_type],
            dispute_type: dispute_type,
            dispute_id: lead_split?.id,
            amount: Number(lead_split?.amount_to_recovered),
          })
        }
      })
    })
  }

  // Utility funtions for current component
  const showConfirmation = () => {
    setConfirmationModal(true);
    document.body.classList.add("overflow-hidden");
  }

  const hideConfirmation = () => {
    setConfirmationModal(false);
    setConfirmationChecked(false);
    document.body.classList.remove("overflow-hidden");
  }

  const handleConfirmationCheckbox = (event) => {
    if (event.target.checked) setConfirmationChecked(true);
    else setConfirmationChecked(false);
  }

  const propsToPass = {
    common_fields, financierId, accessCondition, leadDetail, setIsLoading,
    payViaDisputed, setPayViaDisputed, isPaidViaRecoverySelected,
    disputeData, setDisputeData,
    screenToShow, setScreenToShow, 
    paymentVerificationDetails, setPaymentVerificationDetails,
    splitPaymentDetails, setSplitPaymentDetails,
    tranchData, setTranchData,
    recoveryData, setRecoveryData,
    isPaymentVerificationDoneMarked,
    handleDateChange, savePaymentVerificationOne, savePaymentRecovery, showConfirmation
  }
  const page_name = {
    payment_verification_one: "Payment Verification One",
    payment_verification_two: "Payment Verification Two",
    payment_recovery: "Payment Recovery",
  };

  const allowedAddAdditionalPayment = useMemo(() => {
    const { addPaidOptions, addPaidKeys } = additionalPaymentDetails?.reduce((result, item) => {
      result.addPaidOptions?.push({ label: PAYMENT_TYPE_LABEL[PAYMENT_TYPES_ALTERNATE[item?.payment_type]], value: item?.payment_type });
      result.addPaidKeys[item?.payment_type] = item;
      return result;
    }, { addPaidOptions: [], addPaidKeys: {} }) || { addPaidOptions: [], addPaidKeys: {} };
    const { splitPaidTrancheOptions, splitPaidTrancheRemOptions } = leadDetail?.split_payment?.reduce((result, item) => {
      if ([TRANCHE_STATUS.paid]?.includes(item?.tranche_status)) {
        result.splitPaidTrancheOptions.push({ label: PAYMENT_TYPE_LABEL[item?.payment_type], value: PAYMENT_TYPES_ALTERNATE[item?.payment_type] });
      }
      if ([TRANCHE_STATUS.paid]?.includes(item?.tranche_status) && !addPaidKeys[PAYMENT_TYPES_ALTERNATE[item?.payment_type]]?.id) {
        result.splitPaidTrancheRemOptions.push({ label: PAYMENT_TYPE_LABEL[item?.payment_type], value: PAYMENT_TYPES_ALTERNATE[item?.payment_type] });
      }
      return result;
    }, { splitPaidTrancheOptions: [], splitPaidTrancheRemOptions: [] }) || { splitPaidTrancheOptions: [], splitPaidTrancheRemOptions: [] };
    setAdditionalPaymentOptions([...splitPaidTrancheOptions])
    setAdditionalPaymentRemOptions([...splitPaidTrancheRemOptions])
    console.log("splitPaidTrancheOptions?.length > addPaidOptions?.length", splitPaidTrancheOptions?.length,addPaidOptions?.length);
    return !(splitPaidTrancheOptions?.length > addPaidOptions?.length);
  }, [leadDetail?.split_payment?.length, additionalPaymentDetails])
  return (
    <div className="image-form-outer">
      {loading ? <Loader /> : null}
      <div className="image-tab-panel">
      <DocumentGallery documentCategoryList={documentCategoryList}  />
      </div>
      <div className="form-tab-right-panel ">
        <div className="lead-detail-from-outer">
          <div className="lead-form-heading title-transfer-heading">
            <h2>{page_name[current_page] || `Payment Verification`}</h2>
                {[PAYMENT_VERIFICATION_SCREENS.PAYMENT_VERIFICATION_ONE, PAYMENT_VERIFICATION_SCREENS.PAYMENT_VERIFICATION_TWO].includes(screenToShow) && selectedTab === 'third' && 
              <button type="button" class="btn-line" onClick={() => handleAddAdditionalPayments()}
                disabled={getContext.markAsFreezed || accessCondition || allowedAddAdditionalPayment}
              >
                  <i className='ic-add m-sm-r'></i> Add Payment
                </button>}
          </div>
          {([PAYMENT_VERIFICATION_SCREENS.PAYMENT_VERIFICATION_ONE].includes(screenToShow) &&
              <div className="doument-gallery-tabs">
                  <Tab.Container id="left-tabs-example" activeKey={selectedTab} defaultActiveKey="first" onSelect={(e) => setSelectedTab(e)}>
                      <Nav variant="pills" className="flex-column">
                          <div className="tab-list">
                              <Nav.Item>
                                  <Nav.Link eventKey="first">Dealer Payments</Nav.Link>
                              </Nav.Item>
                              {/* <Nav.Item >
                                  <Nav.Link disabled={accessCondition || leadDetail?.lead_computation_details?.title_transfer_type === "No"} eventKey="second">Title transfer Payments</Nav.Link>
                              </Nav.Item> */}
                              <Nav.Item>
                                  <Nav.Link eventKey="third">Additional Payments</Nav.Link>
                              </Nav.Item>

                          </div>
                      </Nav>
                      <Tab.Content>
                          <Tab.Pane eventKey="first">
                            <PaymentVerificationForm {...propsToPass} />
                          </Tab.Pane>
                          {/* <Tab.Pane eventKey="second">
                                <TitleTransferPayments bankList={bankList} numPayments={numPayments} ttPaymentDetails={ttPaymentDetails} disabledbutton={disabledbutton} isADPCase={isADPCase} setDisabledbutton={setDisabledbutton} handleEditChange={handleEditChange} handleDeleteChange={handleDeleteChange} setTtPaymentDetails={setTtPaymentDetails} handleInputChange={handleInputChange} handleSubmit={handleSubmit} />
                          </Tab.Pane> */}
                          <Tab.Pane eventKey="third">
                            <AdditionalPayments bankList={bankList} savedAdditionalPayments={savedAdditionalPayments} setSavedAdditionalPayments={setSavedAdditionalPayments} numPayments={additionalPaymentDetails.length + savedAdditionalPayments.length} ttPaymentDetails={additionalPaymentDetails} disabledbutton={disabledbutton} isADPCase={isADPCase} setDisabledbutton={setDisabledbutton} handleEditChange={handleEditAdditionalChange} handleDeleteChange={handleDeleteChange} setTtPaymentDetails={setAdditionalPaymentDetails} handleInputChange={handleAdditionalInputChange} handleAdditionalPaymentSubmit={handleAdditionalPaymentSubmit} additionalPaymentOptions={additionalPaymentOptions} additionalPaymentRemOptions={additionalPaymentRemOptions} updateAdditionalPayment={updateAdditionalPayment} deleteAdditionalPayment={deleteAdditionalPayment} handleReupload={handleReupload} showDocs={showDocs} setShowDocs={setShowDocs} showEditDocs={showEditDocs} setShowEditDocs={setShowEditDocs} additionalPaymentDetails={additionalPaymentDetails} setAdditionalPaymentDetails={setAdditionalPaymentDetails} splitTrancheKeyPair={splitTrancheKeyPair} setIsLoading={setIsLoading} />
                          </Tab.Pane>
                      </Tab.Content>
                  </Tab.Container>
              </div>)
            || ([PAYMENT_VERIFICATION_SCREENS.PAYMENT_RECOVERY].includes(screenToShow) && <PaymentRecovery {...propsToPass} />)
            || ([PAYMENT_VERIFICATION_SCREENS.PAYMENT_VERIFICATION_TWO].includes(screenToShow) && <div className="doument-gallery-tabs">
                  <Tab.Container id="left-tabs-example" activeKey={selectedTab} defaultActiveKey="first" onSelect={(e) => setSelectedTab(e)}>
                      <Nav variant="pills" className="flex-column">
                          <div className="tab-list">
                              <Nav.Item>
                                  <Nav.Link eventKey="first">Dealer Payments</Nav.Link>
                              </Nav.Item>
                              {/* <Nav.Item >
                                  <Nav.Link disabled={accessCondition || leadDetail?.lead_computation_details?.title_transfer_type === "No"} eventKey="second">Title transfer Payments</Nav.Link>
                              </Nav.Item> */}
                              <Nav.Item>
                                  <Nav.Link eventKey="third">Additional Payments</Nav.Link>
                              </Nav.Item>

                          </div>
                      </Nav>
                      <Tab.Content>
                          <Tab.Pane eventKey="first">
                            <PaymentVerificationTwo {...propsToPass} />
                          </Tab.Pane>
                          {/* <Tab.Pane eventKey="second">
                                <TitleTransferPayments bankList={bankList} numPayments={numPayments} ttPaymentDetails={ttPaymentDetails} disabledbutton={disabledbutton} isADPCase={isADPCase} setDisabledbutton={setDisabledbutton} handleEditChange={handleEditChange} handleDeleteChange={handleDeleteChange} setTtPaymentDetails={setTtPaymentDetails} handleInputChange={handleInputChange} handleSubmit={handleSubmit} />
                          </Tab.Pane> */}
                          <Tab.Pane eventKey="third">
                            <AdditionalPayments bankList={bankList} savedAdditionalPayments={savedAdditionalPayments} setSavedAdditionalPayments={setSavedAdditionalPayments} numPayments={additionalPaymentDetails.length + savedAdditionalPayments.length} ttPaymentDetails={additionalPaymentDetails} disabledbutton={disabledbutton} isADPCase={isADPCase} setDisabledbutton={setDisabledbutton} handleEditChange={handleEditAdditionalChange} handleDeleteChange={handleDeleteChange} setTtPaymentDetails={setAdditionalPaymentDetails} handleInputChange={handleAdditionalInputChange} handleAdditionalPaymentSubmit={handleAdditionalPaymentSubmit} additionalPaymentOptions={additionalPaymentOptions} additionalPaymentRemOptions={additionalPaymentRemOptions} updateAdditionalPayment={updateAdditionalPayment} deleteAdditionalPayment={deleteAdditionalPayment} handleReupload={handleReupload} showDocs={showDocs} setShowDocs={setShowDocs} showEditDocs={showEditDocs} setShowEditDocs={setShowEditDocs} additionalPaymentDetails={additionalPaymentDetails} setAdditionalPaymentDetails={setAdditionalPaymentDetails} splitTrancheKeyPair={splitTrancheKeyPair}  setIsLoading={setIsLoading} />
                          </Tab.Pane>
                      </Tab.Content>
                  </Tab.Container>
                </div>)
            || ""
          }
          
        </div>
      </div>

      <div className="view-timeline-popup">
        <Modal show={confirmationModal} handleClose={hideConfirmation} >
              <div className="modal-header">
                  <h2>Confirmation</h2>
              </div>
              <div className="modal-body" >
                <div className="custom-control custom-checkbox">
                  <input
                    id={"confirmation-checkbox"}
                    type="checkbox"
                    className={"custom-control-input"}
                    onChange={handleConfirmationCheckbox}
                    checked={confirmationChecked}
                    aria-label="confirmation model"
                  />
                  <label
                    data-label="form"
                    htmlFor="confirmation-checkbox"
                    className="custom-control-label"
                  >
                    {CONFIRMATION_TXT} 
                  </label>
                </div>
              </div>
              <div className="modal-footer" >
                <br></br>
                {!confirmationChecked ? <span className="span-disabled">Proceed to Payment</span> :
                  <button className="btn-primary" onClick={savePaymentVerificationTwo} disabled={!confirmationChecked}>Proceed to Payment</button>
                }
              </div>
        </Modal>
      </div>
    </div>
  );
}
export default PaymentVerification;
import _ from "lodash";
import * as moment from "moment";
export const CUSTOMER_PRIMARY_DOC_ID = 1;
export const CUSTOMER_SECONDARY_DOC_ID = 1;

export const COBORROWER_PRIMARY_DOC_ID = 43;
export const COBORROWER_SECONDARY_DOC_ID = 43;

export const SETTING = {
    CRYPTO_KEY: "U*oDwrIU!B#QX37%#24",
    RESEND_OTP_TIME_IN_SECOND: 30,
};
export const API_URL = process.env.REACT_APP_GETWAY_API;
export const API_KEY = process.env.REACT_APP_API_KEY;  
export const getFileExtensionFromURL = (url) => {
	try {
		return url && url.split(/#|\?/)[0].split('.').pop().trim();
	} catch (e) {
		return '';
	}
}

export const ADMIN_ROLE_ID = 1;

export const IS_USER_ACCESS_PAGE = (controller, action) => {
	let isUserHasRights = false, isEditAccess = false;
	// let loginData = localStorage.getItem("loginUserInfo");
	let loginData = JSON.parse(localStorage.getItem('user_information'));
	 let isSuperAdmin = loginData && loginData?.role_id?.includes(1);
	
	 if(isSuperAdmin){
		isUserHasRights=true;
		isEditAccess=true;
	 }else{
		const userAccessFeatures = JSON.parse(localStorage.getItem("userAccess"));
		if (userAccessFeatures && userAccessFeatures.length) {
			let isKeyExist = _.findKey(userAccessFeatures, function (v) {
				if (controller && v.controller && action && v.action) {
					return (
						v.controller.toLowerCase() === controller.toLowerCase() && 
						v.action.toLowerCase() === action.toLowerCase()
					);
				}
			});
			isUserHasRights = isKeyExist ? true : false;
			isEditAccess = isKeyExist ? ((Number(userAccessFeatures[isKeyExist].access_type) === 1) ? true : false) : false
		}
	}


	return {
		is_access_page: isUserHasRights,
		is_edit_access: isEditAccess
	};
};


export const CHECK_ASSIGN_USER = (assign_to) => {
	let loginData = JSON.parse(localStorage.getItem('user_information'));
	let isSuperAdmin = loginData && loginData.role_id.includes(1);
	if(isSuperAdmin) return false;
	return !(assign_to===loginData?.id);
};
export const TENURE_IN_MONTH = {
    "0": [{id:'12',label:'12',value:'12'},{id:'18',label:'18',value:'18'},{id:'24',label:'24',value:'24'},{id:'30',label:'30',value:'30'},{id:'36',label:'36',value:'36'},{id:'48',label:'48',value:'48'},{id:'60',label:'60',value:'60'}],
    "1": [{id:'24',label:'24',value:'24'},{id:'30',label:'30',value:'30'},{id:'36',label:'36',value:'36'},{id:'48',label:'48',value:'48'}],
    "2": [{id:'12',label:'12',value:'12'},{id:'24',label:'24',value:'24'},{id:'30',label:'30',value:'30'},{id:'36',label:'36',value:'36'},{id:'48',label:'48',value:'48'}],
    "3": [{id:'12',label:'12',value:'12'},{id:'24',label:'24',value:'24'},{id:'30',label:'30',value:'30'},{id:'36',label:'36',value:'36'},{id:'48',label:'48',value:'48'}],
    "4": [{id:'12',label:'12',value:'12'},{id:'24',label:'24',value:'24'},{id:'30',label:'30',value:'30'},{id:'36',label:'36',value:'36'},{id:'48',label:'48',value:'48'}],
    "5": [{id:'30',label:'30',value:'30'},{id:'36',label:'36',value:'36'},{id:'48',label:'48',value:'48'}],
    "6": [{id:'12',label:'12',value:'12'},{id:'18',label:'18',value:'18'},{id:'24',label:'24',value:'24'},{id:'30',label:'30',value:'30'},{id:'36',label:'36',value:'36'},{id:'48',label:'48',value:'48'},{id:'60',label:'60',value:'60'}],
    "7": [{id:'12',label:'12',value:'12'},{id:'18',label:'18',value:'18'},{id:'24',label:'24',value:'24'},{id:'30',label:'30',value:'30'},{id:'36',label:'36',value:'36'},{id:'48',label:'48',value:'48'},{id:'60',label:'60',value:'60'}],
	"8": [{id:'12',label:'12',value:'12'},{id:'18',label:'18',value:'18'},{id:'24',label:'24',value:'24'},{id:'30',label:'30',value:'30'},{id:'36',label:'36',value:'36'},{id:'48',label:'48',value:'48'}],
}

export const STATUS_CODES = {
    "APPLICATION": 1,
    "FINANCIER_APPROVAL": 2,
    "DISBURSEMENT": 3,
    "VEHICLE_TRANSFER": 4,
    "LOST": 5,
    "FINANCIER_COMPUTATION": 6,
}

export const SUB_STATUS_CODES = {
    "CUSTOMER_DETAIL_FILLED": 1,
    "APPLICATION_CREATED": 2,
    "SENT_TO_FINANCIER": 3,
    "SOFT_APPROVAL_RECEIVED": 4,
    "CREDIT_INVESTIGATION_SENT": 5,
    "CREDIT_ADVICE_RECEIVED": 6,
	"CONTRACT_SIGNED": 7,
	"COMPUTATION_GENERATED": 8,
	"COMPUTATION_RESET": 40,
    "COMPUTATION_REGENERATED": 41,
	"PAYMENT_VERIFICATION_DONE": 9,
	"PAID_TO_DEALER": 10,
	"LOAN_VALUE_RECEIVED": 11,
	"CAR_HANDOVER": 12,
	"ORCR_RECEIVED": 13,
	"ORCR_SUBMISSION": 14,
	"ORCR_VERIFIED": 15,
	"REJECT_INCORRECT_DATA": 16,
	"REJECT_JUNK_APPLICATION": 17,
	"CANCEL_BY_CARMUDI": 18,
	"CANCEL_BY_CUSTOMER": 19,
	"CANCEL_BY_DEALER": 20,
	"SOFT_APPROVAL_REJECTED": 21,
	"PAYMENT_CANCELLED": 22,
	"CREDIT_ADVICE_CANCELLED": 23,
	"ENVIRONMENT_CHECK": 24,
	"ORCR_REJECTED": 25,
	"OTHER": 26,
	"DEALER_INCENTIVE_RECEIVED": 27,
	"CREDIT_INVESTIGATION_RECEIVED": 28,
	"BASIC_DETAILS_FILLED": 29,
	"REJECT_APPLICATION_PROCESS_CHECK": 30,
	"COMPUTATION_VERIFIED": 31,
	"COMPUTATION_RESUBMIT": 32,
	"DEALER_INCENTIVE_PAID": 33,
	"LOAN_QUOTE_SELECTED": 37,
	"PAYMENT_VERIFICATION_ONE": 44,
    "PAYMENT_RECOVERY": 45,
    "PAYMENT_VERIFICATION_TWO": 46,
}

export const PAYMENT_PROOF_DOCS = {
    "MAIN_DEALER": 108,
    "MAIN_DEALER_PARENT": 107,
    "SPLIT_DEALER_1": 123,
    "SPLIT_DEALER_1_PARENT": 122,
    "SPLIT_DEALER_2": 125,
    "SPLIT_DEALER_2_PARENT": 124,
    "PROOF_OF_VERIFICATION": 110,
}

export const NAME_WITH_LATIN_CHARACTER_REGEX = /^[^0-9]*$/;

export const MAX_ADVANCE_LOAN_VALUE = 700000;
export const ADVANCE_DEALER_PAID = {
	ADP_PERCENTAGE: 80,
	ADP_SURCHARGE: {
		min: 0,
		max: 10,
		default: 2
	},
	TITLE_TRANSFER_CHARGE: {
		min: 0,
		max: 25000,
		default: 8000
	},
	ADP_PERCENTAGE_RANGE: {
		min: 50,
		max: 100,
		default: 80
	},
} 


export const calculatePercentageValue = (value = 0, percentage = 0) => {
	return (value*percentage)/100;
}

export const VEHICLE_TRANSFER_SUB_STATUS = [12, 13, 14, 15, 38, 39]

export const COUNTRY_CODE = "+63"
export const COUNTRY_CODE_REGEX = /^\+63\s*/

export const ALL_REQUIRED_DOC_IDS_PH = [2, 3, 4, 7, 8, 9, 10, 11, 12, 13];
export const FINANCIER_ID = {
	JACCS_FINANCE:	3
}

export const FINANCIER_DECIMAL_LIMIT = {
	JACCS_FINANCE: 15,
	DEFAULT: 8
}

export const FINANCIER_VALUE_LIMIT = {
	JACCS_FINANCE: 16,
	DEFAULT: 16
}

export const ROUNDOFF_TO_MAX_LIMIT = (value, limit) => {
	return (value.includes(".") && value.slice(0, (+limit+1))) || value.slice(0, limit);
}

export const TRANCHE_STATUS_LABEL = {
    "paid": "Paid",
    "on_hold": "On Hold",
    "requested": "Requested",
    "generated": "Generated"
}

export const TRANCHE_STATUS = {
    "paid": "paid",
    "on_hold": "on_hold",
    "requested": "requested",
    "generated": "generated"
}

export const TITLE_TRANSFER_STATUS = {
    "paid": "Paid",
    "partial": "Partial",
    "requested": "Requested",
    "generated": "Generated",
}

export const computationRegeneratedActiveField = [
	"chattel_percentage",
	"chattel_fee",
	"dst_charges",
	"out_of_town_charges",
	"di_amount_pass_through",
	"ongoing_promo",
	"dealer_amount_from_financier_percent",
	"di_amount_from_financier_to_carmudi_percent",
	"di_amount_from_financier_to_carmudi_tax_percent",
	"dealer_incentive_percentage_gross",
	"carmudi_top_up_percentage",
	"dealer_incentive_tax_percent",
	"surcharge_for_adp",
	"title_transfer_charge",
	"vat_input",
	"exclusiveper",
	"inclusiveper",
]

// di_amount_from_financier_to_carmudi_percent
export const isRegeneratedFieldNeedToActive = (field_name) => {
	return !computationRegeneratedActiveField.includes(field_name);
}

export const NO_TOUCH_POLICY_FINANCIER = [1, 2, 4, 11];

export const CA_RECEIVED_OPTIONS = [
	{ value: "Yes", label: "Yes" },
	{ value: "No", label: "No" }
];


export const FIELDS_TO_DISABLE_IF_EXIST = {
	CREDIT_ADVICE_RECEIVED: ["custom_credit_advice_received_date", "pre_approval_received_date"]
};
export const isNeedsToDisable = (field_name, fieldFrom = [], value) => {
	return value && fieldFrom.includes(field_name);
}

export const TRUCK_IDS = [7, 8, 9];

export const CUSTOM_DATE_TYPE_OPTIONS = [
  { value: 'custom_contract_signed_date', label: 'Custom Contract Signed Date' }
]

export const getKeysDataIfExist = (object = {}, array = []) => {
	return array.reduce((result, key)=>{
		if(Array.isArray(key)) {
			if(object[key[0]]) result[key[1]] = object[key[0]];
		}
		else {
			if(object[key]) result[key] = object[key];
		}
		return result;
	},{}) || {};
}

export const getKeysData = (object = {}, array = []) => {
	return array.reduce((result, key)=>{
		if(Array.isArray(key)) {
			if(object?.[key?.[0]]) result[key[1]] = object?.[key?.[0]];
		}
		else {
			if(object?.[key]) result[key] = object?.[key];
		}
		return result;
	},{}) || {};
}
export const ORCR_VERIFICATION_STATUS = {
    NOTVERIFIED: "Not Verified",
    VERIFIED: "Verified"
}

export const prepareTemplateDataIfExist = (object = {}, array = []) => {
    return (
        array.reduce((result, key) => {
            if (Array.isArray(key)) {
                result[`{{${key[1]}}}`] = (object[key[0]] || object[key[0]] === 0) ? object[key[0]] : "";
            } else {
                result[`{{${key}}}`] = (object[key] || object[key] === 0) ? object[key] : "";
            }
            return result;
        }, {}) || {}
    );
}

export const TT_PAYMENT_LABEL = {
	"title_transfer_payment1": "Title Transfer Payment 1",
	"title_transfer_payment2": "Title Transfer Payment 2"
}

export const TT_PAYMENT_SORT_ORDER = {
	"title_transfer_payment1": 1, 
	"title_transfer_payment2": 2
};

export const GOOGLE_AUTH_URL = process.env.REACT_APP_GOOGLE_AUTH_URL || "https://sso.carmudi.com.ph/google?redirect=";

export const LOAN_VALUE_RECEIVED = [
	{ value: 'Yes', label: 'Yes' },
	{ value: 'No', label: 'No' }
];

export const DEALER_INCENTIVE_RECEIVED = [
	{ value: 'Yes', label: 'Yes' },
	{ value: 'No', label: 'No' }
];

export const AGEING_DAYS_OPT = [
	{ value: "1", label: '1 Day' },
	{ value: "2", label: '2 Days' },
	{ value: "3", label: '3 Days' },
	{ value: "4", label: '4 Days' },
	{ value: "5", label: '5 Days' },
	{ value: "6", label: 'More Than 5 Days' },
	{ value: "0", label: 'Less Than a Day' }
];

export const MTD_OPTIONS = [
	{ value: "All", label: 'All' },
	{ value: "Today", label: 'Today' },
	{ value: "MTD", label: 'MTD' },
	{ value: "QTD", label: 'QTD' },
	{ value: "YTD", label: 'YTD' }
];

export const getDateRangeRangeCode = (name) => {
	const response = {};
    switch (name) {
      case 'Today':
        response.from_date = moment().startOf('day');
        response.to_date = moment().endOf('day');
        break;
      case 'MTD': // Month to date
        response.from_date = moment().startOf('month');
        response.to_date = moment();
        break;
      case 'QTD': // Quarter to date
        response.from_date = moment().startOf('quarter');
        response.to_date = moment();
        break;
      case 'YTD': // Year to date
        response.from_date = moment().startOf('year');
        response.to_date = moment();
        break;	
	};
	// For 'All' option, you can set wide date range or keep it empty
	if(response?.from_date) response.from_date = response?.from_date?.format('YYYY-MM-DD');
	if(response?.to_date) response.to_date = response?.to_date?.format('YYYY-MM-DD');
	return response;
  };
  
export const SOURCE_KEY_VAL = {
	'mapped_dealer': 'Mapped Dealer',
	'unmapped_dealer': 'Unmapped Dealer',
	'agent': 'Agent',
	'backend': 'Backend',
	'sm': 'SM',
	'dealer': 'Dealer'
};


export const PAYMENT_VERIFICATION_SCREENS = {
	PAYMENT_VERIFICATION_ONE: "payment_verification_one",
	PAYMENT_VERIFICATION_TWO: "payment_verification_two",
	PAYMENT_RECOVERY: "payment_recovery",
}

export const PAYMENT_TYPE = {
    "MainDealer": "main_dealer",
    "OnHoldDealer": "onhold_dealer",
    "SplitDealer": "split_dealer",
    "SplitDealer2": "split_dealer2"
}

export const PAYMENT_TYPE_LABEL = {
    main_dealer: "Main Dealer",
    onhold_dealer: "Onhold Dealer",
    split_dealer: "Split Dealer 1",
	split_dealer2: "Split Dealer 2",
    MainDealer: "Main Dealer",
    OnHoldDealer: "Onhold Dealer",
    SplitDealer: "Split Dealer 1",
	SplitDealer2: "Split Dealer 2",
	extra_main_dealer: "Extra Main Dealer Payment",
	extra_onhold_dealer: "Extra On Hold Dealer Payment",
	extra_split_dealer: "Extra Split Dealer 1 Payment",
	extra_split_dealer2: "Extra Split Dealer 2 Payment",
}

export const PAYMENT_TYPE_AMOUNT_LABEL = {
	main_dealer: "Main Dealer Amount",
	onhold_dealer: "On Hold Dealer Amount",
	split_dealer: "Split Dealer Amount",
	split_dealer2: "Split Dealer Amount"
};


export const PAYMENT_STATUS = {
	generated: "Generated",
	requested: "Requested",
	on_hold: "On Hold",
	paid: "Paid",
}

export const TRANCH_ORDER = {
	"main_dealer": 1,
	"onhold_dealer": 2,
	"split_dealer": 3,
	"split_dealer2": 4
};

export const DisputeType = {
    "Disputed": "Disputed",
    "LostAfterDisbursal": "LostAfterDisbursal",
};

export const disableTrancheBankFields = {
	bank_name: true,
	branch_name: true,
	account_holder_name: true,
	account_number: true
};
export const disableTrancheAmountField = {
	main_dealer: true,
	onhold_dealer: true,
	split_dealer: true,
	split_dealer2: true
}

export const CONFIRMATION_TXT = "I hereby affirm that I have diligently examined all pertinent payment details for this case and assert with confidence that it is secure for us to proceed with this transaction";

export const EXPORT_OPTIONS = [
	{ value: 'Loan id', label: 'Loan id' },
	{ value: 'Dealer/Agent', label: 'Dealer/Agent' },
	{ value: 'borrower_name', label: 'Borrower name' },
	{ value: 'financier_name', label: 'Financier name' },
	{ value: 'application_submitted_date', label: 'Application submitted date' },
	{ value: 'agent_id', label: 'Agent id' },
	{ value: 'agent_name', label: 'Agent name' },
	{ value: 'so_id', label: 'So id' },
	{ value: 'so_name', label: 'So name' },
	{ value: 'sm_id', label: 'Sm id' },
	{ value: 'sm_name', label: 'Sm name' },
	{ value: 'th_id', label: 'Th id' },
	{ value: 'th_name', label: 'Th name' },
	{ value: 'make', label: 'make' },
	{ value: 'model', label: 'model' },
	{ value: 'variant', label: 'variant' },
	{ value: 'year', label: 'year' },
	{ value: 'customer_contact_no', label: 'Customer contact no' },
	{ value: 'vehicle_type', label: 'Vehicle type' },
	{ value: 'dealer_size', label: 'Dealer size' },
	{ value: 'dealer_name', label: 'Dealer name' },
	{ value: 'gcd_code', label: 'gcd code' },
	{ value: 'region', label: 'region' },
	{ value: 'City', label: 'City' },
	{ value: 'ops_assigned', label: 'Ops assigned' },
	{ value: 'Loan_Exp_car_fmv', label: 'Loan Exp car fmv' },
	{ value: 'Loan_Exp_dp_amount', label: 'Loan Exp dp amount' },
	{ value: 'Loan_Exp_loan_amount', label: 'Loan Exp loan amount' },
	{ value: 'Loan_Exp_tenure', label: 'Loan Exp tenure' },
	{ value: 'doc_status', label: 'Doc status' },
	{ value: 'sub_status', label: 'Sub status' },
	{ value: 'status', label: 'status' },
	{ value: 'rejected_date', label: 'Rejected date' },
	{ value: 'soft_approval_received_date', label: 'Soft approval received date' },
	{ value: 'credit_advice_received_date', label: 'Credit advice received date' },
	{ value: 'contract_signing_date', label: 'Contract signing date' },
	{ value: 'custom_contract_signed_date', label: 'Custom contract signed date' },
	{ value: 'car_handover_date', label: 'Car handover date' },
	{ value: 'orcr_verification_date', label: 'Orcr verification date' },
	{ value: 'orcr_transferred_date', label: 'Orcr transferred date' },
	{ value: 'Loan Amount Pass through', label: 'Loan Amount Pass through' },
	{ value: 'ADP case', label: 'ADP case' },
	{ value: 'ADP Parameter', label: 'ADP Parameter' },
	{ value: 'ADP %age Value', label: 'ADP %age Value' },
	{ value: 'Advance Loan Value to be Paid', label: 'Advance Loan Value to be Paid' },
	{ value: 'Remaining Loan Value to be Paid', label: 'Remaining Loan Value to be Paid' },
	{ value: 'Chattel Percentage (computation)', label: 'Chattel Percentage (computation)' },
	{ value: 'Chattel Fee Amount', label: 'Chattel Fee Amount' },
	{ value: 'DST Charges', label: 'DST Charges' },
	{ value: 'Out of Town Charges', label: 'Out of Town Charges' },
	{ value: 'Total Fee by Customer', label: 'Total Fee by Customer' },
	{ value: 'Total Payment by Customer', label: 'Total Payment by Customer' },
	{ value: 'DI Amount Pass through', label: 'DI Amount Pass through' },
	{ value: 'Direct Dealer Payout from Financier %age', label: 'Direct Dealer Payout from Financier %age' },
	{ value: 'Direct Dealer Payout from Financier Amount', label: 'Direct Dealer Payout from Financier Amount' },
	{ value: 'DI amount from Financier to Carmudi %age', label: 'DI amount from Financier to Carmudi %age' },
	{ value: 'DI amount from Financier to Carmudi Amount', label: 'DI amount from Financier to Carmudi Amount' },
	{ value: 'DI amount from Financier to Carmudi Tax %age', label: 'DI amount from Financier to Carmudi Tax %age' },
	{ value: 'DI amount from Financier to Carmudi Amount Net of Tax', label: 'DI amount from Financier to Carmudi Amount Net of Tax' },
	{ value: 'Dealer Incentive from Financier Net of Tax Received', label: 'Dealer Incentive from Financier Net of Tax Received' },
	{ value: 'Dealer Incentive from Financier Net of Tax Received Date', label: 'Dealer Incentive from Financier Net of Tax Received Date' },
	{ value: 'Advance Amount', label: 'Advance Amount' },
	{ value: 'Paid Through', label: 'Paid Through' },
	{ value: 'Carmudi Dealer Incentive %age (Gross)', label: 'Carmudi Dealer Incentive %age (Gross)' },
	{ value: 'Carmudi Dealer Incentive %age (Net)', label: 'Carmudi Dealer Incentive %age (Net)' },
	{ value: 'Carmudi Dealer Incentive Amount', label: 'Carmudi Dealer Incentive Amount' },
	{ value: 'Carmudi Top-up %', label: 'Carmudi Top-up %' },
	{ value: 'Carmudi Top-up Amount', label: 'Carmudi Top-up Amount' },
	{ value: 'Total Carmudi Dealer Incentive %age', label: 'Total Carmudi Dealer Incentive %age' },
	{ value: 'Total Carmudi Dealer Incentive Amount', label: 'Total Carmudi Dealer Incentive Amount' },
	{ value: 'Remaining Carmudi Dealer Incentive Amount', label: 'Remaining Carmudi Dealer Incentive Amount' },
	{ value: 'Carmudi Dealer Incentive Tax %age', label: 'Carmudi Dealer Incentive Tax %age' },
	{ value: 'Total Carmudi Dealer Incentive Amount Net of Tax to be Paid', label: 'Total Carmudi Dealer Incentive Amount Net of Tax to be Paid' },
	{ value: 'Surcharge for ADP', label: 'Surcharge for ADP' },
	{ value: 'Title Transfer Charge', label: 'Title Transfer Charge' },
	{ value: 'Dealer Incentive Net of Tax to be Paid', label: 'Dealer Incentive Net of Tax to be Paid' },
	{ value: 'Total Amount Paid', label: 'Total Amount Paid' },
	{ value: 'Main Dealer Payment', label: 'Main Dealer Payment' },
	{ value: 'On Hold Dealer Payment Amount', label: 'On Hold Dealer Payment Amount' },
	{ value: 'Split Payment 1', label: 'Split Payment 1' },
	{ value: 'Split Payment 2', label: 'Split Payment 2' },
	{ value: 'Title Transfer Payment 1 Amount', label: 'Title Transfer Payment 1 Amount' },
	{ value: 'Title Transfer Payment 2 Amount', label: 'Title Transfer Payment 2 Amount' },
	{ value: 'Main Dealer Payment Paid Date', label: 'Main Dealer Payment Paid Date' },
	{ value: 'On Hold Dealer Payment Paid Date', label: 'On Hold Dealer Payment Paid Date' },
	{ value: 'Split Payment 1 Paid Date', label: 'Split Payment 1 Paid Date' },
	{ value: 'Split Payment 2 Paid Date', label: 'Split Payment 2 Paid Date' },
	{ value: 'Title Transfer Payment 1 Paid Date', label: 'Title Transfer Payment 1 Paid Date' },
	{ value: 'Title Transfer Payment 2 Paid Date', label: 'Title Transfer Payment 2 Paid Date' },
	{ value: 'Installment (computation)', label: 'Installment (computation)' },
	{ value: 'Interest Rate Percentage (computation)', label: 'Interest Rate Percentage (computation)' },
	{ value: 'Tenure (computation)', label: 'Tenure (computation)' },
	{ value: 'interested_in_buying_insurance', label: 'Interested in buying insurance' },
	{ value: 'Exclusive %age', label: 'Exclusive %age' },
	{ value: 'revenue_excluding_vat', label: 'Revenue excluding vat' },
	{ value: 'Inclusive %age', label: 'Inclusive %age' },
	{ value: 'revenue_including_vat', label: 'Revenue including vat' },
	{ value: 'VAT Amount', label: 'VAT Amount' },
	{ value: 'Customer nationality', label: 'Customer nationality' },
	{ value: 'Customer DOB', label: 'Customer DOB' },
	{ value: 'Customer House Number', label: 'Customer House Number' },
	{ value: 'Customer Street', label: 'Customer Street' },
	{ value: 'Customer Region', label: 'Customer Region' },
	{ value: 'Customer City', label: 'Customer City' },
	{ value: 'Customer Barangay', label: 'Customer Barangay' },
	{ value: 'Customer Postal Code', label: 'Customer Postal Code' },
	{ value: 'Customer gross monthly income', label: 'Customer gross monthly income' },
	{ value: 'Customer designation', label: 'Customer designation' },
	{ value: 'Co-borrower name', label: 'Co-borrower name' },
	{ value: 'Co-borrower mobile number', label: 'Co-borrower mobile number' },
	{ value: 'Co-borrower nationality', label: 'Co-borrower nationality' },
	{ value: 'Co-borrower DOB', label: 'Co-borrower DOB' },
	{ value: 'Co-borrower House Number', label: 'Co-borrower House Number' },
	{ value: 'Co-borrower Street', label: 'Co-borrower Street' },
	{ value: 'Co-borrower Region', label: 'Co-borrower Region' },
	{ value: 'Co-borrower City', label: 'Co-borrower City' },
	{ value: 'Co-borrower Barangay', label: 'Co-borrower Barangay' },
	{ value: 'Co-borrower Postal Code', label: 'Co-borrower Postal Code' },
	{ value: 'Co-borrower gross monthly income', label: 'Co-borrower gross monthly income' },
	{ value: 'Co-borrower designation', label: 'Co-borrower designation' },
	{ value: 'Vehicle Plate Number', label: 'Vehicle Plate Number' },
	{ value: 'Remarks', label: 'Remarks' },
	{ value: 'Ageing Days - Loan Amount', label: 'Ageing Days - Loan Amount' },
	{ value: 'Ageing Days - Dealer Incentive', label: 'Ageing Days - Dealer Incentive' },
	{ value: 'FMV (computation)', label: 'FMV (computation)' },
	{ value: 'Down Payment Amount (computation)', label: 'Down Payment Amount (computation)' },
	{ value: 'Loan Value (computation)', label: 'Loan Value (computation)' },
	{ value: 'Vehicle Usage (computation)', label: 'Vehicle Usage (computation)' },
	{ value: 'Vehicle Type (computation)', label: 'Vehicle Type (computation)' },
	{ value: 'Brand (computation)', label: 'Brand (computation)' },
	{ value: 'Model (computation)', label: 'Model (computation)' },
	{ value: 'Variant (computation)', label: 'Variant (computation)' },
];

export const DOC_STATUS = {
	PENDING: 0,
	SUBMITTED: 1,
	APPROVED: 2
}

export const ROLES = {
    CEO: 2,
    NATIONAL_HEAD: 3,
    TERRITORY_HEAD: 4,
    SALES_MANAGER: 5, 
    SALES_OFFICER: 6,
    AGENT: 8
}

export const PAYMENT_TYPES = {
	MAIN_DEALER: "main_dealer",
    ON_HOLD_DEALER: "onhold_dealer",
    SPLIT_DEALER: "split_dealer",
    SPLIT_DEALER_2: "split_dealer2",
	EXTRA_MAIN_DEALER: "extra_main_dealer",
    EXTRA_ON_HOLD_DEALER: "extra_onhold_dealer",
    EXTRA_SPLIT_DEALER: "extra_split_dealer",
    EXTRA_SPLIT_DEALER_2: "extra_split_dealer2"
}

export const PAYMENT_TYPES_ALTERNATE = {
	extra_main_dealer: "main_dealer",
    extra_onhold_dealer: "onhold_dealer",
    extra_split_dealer: "split_dealer",
    extra_split_dealer2: "split_dealer2",
	main_dealer: "extra_main_dealer",
    onhold_dealer: "extra_onhold_dealer",
    split_dealer: "extra_split_dealer",
    split_dealer2: "extra_split_dealer2"
}

export const DISPUTE_STATUS = {
	RAISED : 'raised',
    IN_PROGRESS : 'in_progress',
    RESOLVED : 'resolved',
}

export const DISPUTED_FLAG_OPT = [
	{ label: 'Raised', value: "raised" },
	{ label: 'In Progress', value: "in_progress" },
	{ label: 'Resolved', value: "resolved" },
];

export const PAYMENT_TYPES_OPT = [
	{label: "Main Dealer Payment", value: "main_dealer"},
    {label: "On Hold Dealer Payment", value: "onhold_dealer"},
    {label: "Split Dealer 1 Payment", value: "split_dealer"},
    {label: "Split Dealer 2 Payment", value: "split_dealer2"},
]



export const TITLE_BOX_ACTION = {
    lead_created: "lead_created",
    lead_completed: "lead_completed",
    lead_lost: "lead_lost"
};

export const MIN_LOAN_AMOUNT = 150000;


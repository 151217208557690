import { addValidationRule } from 'formsy-react';
import React, { useEffect, useState } from 'react';
import { getNumberFormatSeperator, updateObject } from '../../../common/helper';
import { disableTrancheAmountField, disableTrancheBankFields, FINANCIER_DECIMAL_LIMIT, FINANCIER_ID, PAYMENT_TYPE, PAYMENT_TYPE_AMOUNT_LABEL, PAYMENT_TYPE_LABEL } from '../../../config/constants';
import FormsyCheckBox from '../../elements/FormsyCheckBox';
import FormsyInputField from '../../elements/FormsyInputField';
import FormsySelect from '../../elements/FormsySelect';
import payment_verification_fields from '.././formFields/paymentVerification.json';
const SPLIT_PAYMENT_FIELDS = payment_verification_fields?.split_payment_details_v2 || [];
const CUSTOM_OPTION = {id:0,label:"Custom",value:"custom"};

const TRANCHE_STATUS = {
  "paid": "Paid",
  "on_hold": "On Hold",
  "requested": "Requested"
}

const SplitPaymentFormTwo = (props) => {
  addValidationRule('isValidAccountNumber', function(values, value) {
    const accountNumbers = splitPaymentDetails.reduce((result, details) => { if([PAYMENT_TYPE.SplitDealer, PAYMENT_TYPE.SplitDealer2].includes(details?.payment_type) && details.account_number) result.push(details.account_number); return result; }, []);
    let bank_acc_number_match =  accountNumbers.some((accountNumber, index) => {
      return accountNumbers.indexOf(accountNumber) !== index;
    });

    if(bank_acc_number_match)
      return "Account number of all split payment must be different";
    else
      return true;
  });

  addValidationRule('isValidDealerPayment', function(values, value) {
    let split_payment_amount = splitPaymentDetails.length ? splitPaymentDetails.reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.amount), 0) : 0;
    let main_dealer = splitPaymentDetails.length ? parseFloat(splitPaymentDetails[0]["amount"]) : 0;

    // if(main_dealer <=0)
    //  return "Main Dealer Payment should be greater than zero";
    // if(Number(value) <= 0 && value)
    //  return "Amount should be greater than zero";
    // if(parseFloat(Math.round(split_payment_amount * 100) / 100) !== parseFloat(Math.round(values.total_payment_to_dealer * 100) / 100) && !mainDealerFlag)
    //   return "Sum of Split Payments should be equal to Total payment to dealer";
    // else
    //   return true;
  });

  let { split_trance,
    paymentVerificationDetails, setPaymentVerificationDetails,
    splitPaymentDetails, setSplitPaymentDetails,
    tranchData, setTranchData,
    index, financierId, leadDetail, handleCheckboxChange
  } = props;
  const [bankOptions, setBankOptions] = useState([]);

  useEffect(() => {
    let bankData = [];
    if(leadDetail?.dealerData) {
      leadDetail?.dealerData?.[0]?.bank_details?.map((dealer,index) => {
        return bankData.push({
          id: index + 1,
          label: dealer.account_number + " - " + dealer.branch_name,
          value: dealer.account_number,
        })
      })
    }
    bankData.push(CUSTOM_OPTION)
    setBankOptions(bankData);
  }, [leadDetail])

  const handleInputChange = (target_id, event, type, payment_type, index) => {
    let value = type === "number-format" ? event.floatValue : type === 'dropdown' ? event.value : event.target.value;
    let updatedSplitPayment = [...splitPaymentDetails];
    if (target_id) {
        if(payment_type === "mandatory_fields"){
            setPaymentVerificationDetails(currentValue => ({
                ...currentValue,
                [target_id]: value
            }))
        }else if(payment_type === "split_payment" && index != null){
            if(target_id === 'bank_account') {
              leadDetail?.dealerData?.[0]?.bank_details?.forEach(dealer => {
                if(dealer.account_number === value) {
                  updatedSplitPayment[index][target_id] = value;
                  updateObject(updatedSplitPayment[index], dealer || {}, ["bank_name", "branch_name", "account_holder_name", "account_number"]);
                }
              })
              const isOnholdTrachPresent = splitPaymentDetails?.find(item => item?.payment_type === PAYMENT_TYPE.OnHoldDealer);
              if (isOnholdTrachPresent && PAYMENT_TYPE.MainDealer === payment_type) {
                updateObject(isOnholdTrachPresent, { ...updatedSplitPayment[0] }, ["bank_name", "branch_name", "account_holder_name", "account_number"]);
                isOnholdTrachPresent[target_id] = value;
              }
              if(value === 'custom') {
                updatedSplitPayment[index] = {
                  ...updatedSplitPayment[index],
                  [target_id]: value,
                  bank_name: '',
                  branch_name: '',
                  account_holder_name: '',
                  account_number: ''
                }
              }
            }
            else if(target_id === "amount_to_be_paid")
              updatedSplitPayment[index] = {...updatedSplitPayment[index], [target_id]: event?.target?.value ? parseFloat(event?.target?.value.replace(/,/g, '')) : 0}
            else
              updatedSplitPayment[index] = {...updatedSplitPayment[index], [target_id]: value}
            setSplitPaymentDetails([...updatedSplitPayment]);
        }
    }
}
  
  const isCheckboxChecked = () => {
    const isChecked = ['paid', 'requested'].includes(splitPaymentDetails?.[index]?.tranche_status) || splitPaymentDetails?.[index]?.is_tranche_marked;
    if (isChecked === undefined) return false;
    return isChecked;
  };

  let selectCondition = (leadDetail?.is_pushed_to_pb === "1" && !splitPaymentDetails?.[index]?.is_tranche_marked) || false;

    return (
        <div className="row"> 
        { 
            <div className="col-md-12">
              <div className='split-act-btn'> 
              <FormsyCheckBox
                id="tranch_checkbox"
                name={`tranch_checkbox_${splitPaymentDetails?.[index]?.payment_type}`}
                type="checkbox"
                className="custom-control-input"
                value="1"
                checked={isCheckboxChecked()}
                onChange={(e) => handleCheckboxChange(e,index)}
                disabled={(leadDetail?.is_pushed_to_pb==="1" && ['requested','paid'].includes(leadDetail?.split_payment?.find(split => split.payment_type === splitPaymentDetails[index]?.payment_type)?.tranche_status))}
              />
                <h3>{ PAYMENT_TYPE_LABEL?.[splitPaymentDetails?.[index]?.payment_type]}</h3>
                <span className={splitPaymentDetails?.[index]?.tranche_status && 'btn-primary-accent history-btn tranche-status' || ''}>{TRANCHE_STATUS[split_trance?.tranche_status]}</span>
              </div>
            </div>
        }
        {SPLIT_PAYMENT_FIELDS?.map((field) => {
        return (
            ["text", "pattern-format", "number-format"].includes(field?.type) ? 
          <fieldset class="form-filed col-md-6 ">
              <FormsyInputField
                id={"split_"+field?.id}
                name={field?.name}
                type={field?.type}
                value={(splitPaymentDetails?.[index]?.[field?.name] || ["amount_to_be_paid"].includes(field?.name) && splitPaymentDetails?.[index]?.["amount"] || "")}
                placeholder=" "
                {...getNumberFormatSeperator(field?.type)}
                label={(field?.name === "amount_to_be_paid" && PAYMENT_TYPE_AMOUNT_LABEL[splitPaymentDetails?.[index]?.payment_type]) || field?.label}
                onChange={(e) => handleInputChange(field?.id, e, field?.type, "split_payment", index)}
                format={field?.ApplyNumberformat ? field?.format : ""}
                // validations={(field?.name === "amount_to_be_paid" && index !==0) ? "isValidDealerPayment" : (field?.name === "account_number" && index !==0) ? "isValidAccountNumber" : field?.validations}
                validationError={field?.validationError}
                required={isCheckboxChecked() || ([PAYMENT_TYPE.MainDealer, PAYMENT_TYPE.OnHoldDealer].includes(splitPaymentDetails?.[index]?.payment_type)) || (field?.name === 'amount_to_be_paid' && [PAYMENT_TYPE.SplitDealer, PAYMENT_TYPE.SplitDealer2].includes(splitPaymentDetails?.[index]?.payment_type))}
                disabled={ 
                  selectCondition
                  || ["requested", "paid"].includes(splitPaymentDetails[index]?.tranche_status)
                  || (index === 0 && leadDetail?.dealerData?.[0]?.[field?.name] !== null)
                  || (field?.name === "amount_to_be_paid" && disableTrancheAmountField?.[splitPaymentDetails[index]?.payment_type])
                  || (splitPaymentDetails[index]?.bank_account !== 'custom' && disableTrancheBankFields?.[field?.name])
                }
                decimalScale={(Number(financierId) === FINANCIER_ID.JACCS_FINANCE && FINANCIER_DECIMAL_LIMIT.JACCS_FINANCE) || FINANCIER_DECIMAL_LIMIT.DEFAULT}
              />
          </fieldset>
           :
            field?.type === "dropdown" ? 
              <fieldset class="single-select col-md-6">
                <FormsySelect
                  name={field.name}
                  id={field.id}
                  inputProps={{
                    options: ([PAYMENT_TYPE.SplitDealer, PAYMENT_TYPE.SplitDealer2].includes(splitPaymentDetails?.[index]?.payment_type)) ? [...bankOptions?.filter(data => {
                      return data.value!=='custom' && splitPaymentDetails?.every(split => split.account_number !== data.value)
                    }),CUSTOM_OPTION] : bankOptions?.filter(data => data.value !== 'custom'),
                    placeholder: field.label,
                    className: "react-select",
                    classNamePrefix: "react-select",
                    value: bankOptions?.filter(({ value }) => splitPaymentDetails?.[index]?.bank_account?.includes(value)),
                    isDisabled: (['requested','paid'].includes(leadDetail?.split_payment?.find(split => split.payment_type === splitPaymentDetails?.[index]?.payment_type)?.tranche_status) || selectCondition) || ([PAYMENT_TYPE.OnHoldDealer].includes(splitPaymentDetails?.[index]?.payment_type))
                    }}
                  value={splitPaymentDetails[index]?.bank_account}
                  onChange={(e) => handleInputChange(field.id, e, field.type, "split_payment", index)}
                  required={isCheckboxChecked() || ([PAYMENT_TYPE.MainDealer].includes(splitPaymentDetails?.[index]?.payment_type))}
                />
              </fieldset>
            : <></>
        )})}
        
      </div>
    );
}
export default SplitPaymentFormTwo;